import React from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO"

export default () => {
  return (
    <Layout>
      <Seo />
      <h1>Rough Year, Eh?</h1>
      <p>
        Planning a wedding during a pandemic is tricky. Like, really tricky. And
        attending one can be a little dicey too, depending on your comfort
        level. Navigating the safety and social hazards can add a lot of
        complexity. To help reduce some of the uncertainty, here's some of the
        considerations we've made with regard to the pandemic:
      </p>
      <ul className="chevron-list">
        <li>
          <strong>
            Per New York State requirements, please get tested for Covid 2-3
            days prior to attending and bring a copy of your test results to the
            event. (How romantic!) This does not apply to those that are already
            fully vaccinated.
          </strong>
        </li>
        <li>The guest list has been limited to ~45 people.</li>
        <li>
          The ceremony will be held outdoors. If the weather fails to cooperate,
          we'll be hosting inside the Wren's Roost Barn and under the outdoor
          tent. We'll also be keeping the doors open to promote air flow.
        </li>
        <li>
          We request that everyone bring a mask and wear it during the ceremony
          and when interacting with catering staff.
        </li>
        <li>
          We'll have a food truck serving up the evening meal to allow for
          staggered mealtimes.
        </li>
        <li>
          Feel free to bring a picnic blanket for mealtime if you'd like a
          little extra space to yourself. In addition to ample table seating,
          there's an enormous lawn for you to spread out on.
        </li>
      </ul>
    </Layout>
  )
}
